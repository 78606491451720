<template>
  <div shot-val-mvp-result>
    <div radiant-result>
      <h3 class="radiant">레디언트 스테이지 1,2,3위 팀</h3>
      <table>
        <thead>
          <tr>
            <th>순위</th>
            <th>팀명</th>
            <th>순위보상</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank1.svg" />
            </td>
            <td>TNL Esports</td>
            <td>1,300만원</td>
          </tr>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank2.svg" />
            </td>
            <td>VVS_</td>
            <td>600만원</td>
          </tr>
          <tr>
            <td rowspan="2">
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank3.svg" />
            </td>
            <td>이사가자</td>
            <td rowspan="2">각 400만원</td>
          </tr>
          <tr>
            <td>WGS X-IT</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div mvp-result>
      <h3>MVP</h3>
      <table>
        <thead>
          <tr>
            <th>팀명</th>
            <th>선수명</th>
            <th>보상</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TNL Esports</td>
            <td>TS</td>
            <td>100만원</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShotValMvpResult',

};

</script>

<style lang="less">
@import '~@/less/proj.less';

[shot-val-mvp-result] {
  h3 { .wh(303, 44); .fs(18, 44px); .c(white); .tc; .mh-c; .mb(10); .no-repeat; }
  table {
    thead { .fs(14, 14px); .tc; .c(#89898a); .medium; .bgc(#131315);
      > tr {
        > th { .-r(#27292d, 1px); .h(30);}
      }
    }
    tbody { .bgc(#191b1f);
      > tr { .-b(#d2d0ca, 1px); .h(30);
        > td { .fs(14); .c(white); .bold; .mb(4); .-a(#27292d, 1px); .tc; .h(30);
          &:nth-of-type(3) { .c(#ffc700);}
          > img { .wh(20, 24);}
        }
      }
    }
  }
  [radiant-result] {.tc; .w(303); .mh-c; .mt(26);
    h3 { .bg('@{val-home}/leaderboard/m/subtitle/subtitle_type=rad.svg'); }
  }
  [mvp-result] {.tc; .w(303); .mh-c; .mt(26);
    h3 { .bg('@{val-home}/leaderboard/m/subtitle/subtitle_type=mvp.svg'); }
    table {
      tbody {
        > tr {
          > td {
            &:nth-of-type(2) { .regular;}
          }
        }
      }
    }
  }
  @media (@tp-up) {
    [radiant-result] { .w(482); .mh-c; .mt(31);
      h3 { .wh(482, 40); .fs(18, 40px); .bg('@{val-home}/result/rad_1920.svg'); .no-repeat; background-size: contain;}
    }
    [mvp-result] {.w(482); .mh-c;
      h3 { .wh(482, 40); .fs(18, 40px); .bg('@{val-home}/result/mvp_1920.svg'); .no-repeat; background-size: contain;}

    }
  }
}

</style>
