<template>
  <ul shot-val-detail>
    <li>
      <img src="/img/asset/logo/VA_Final_logo.png" class="img-logo"/>
      <h2>총 상금 4,500만원을 건<br class="hidden-tv-down"/> 전국 발로란트 유저들의 대축제</h2>
      <div class="schedule">
        <label>접수기간</label>
        <p><b>골드/다이아</b><em>10월 4일(월) ~ 10월 14일(목)</em></p>
        <p><b>레디언트</b><em>10월 4일(월) ~ 10월 21일(목)</em></p>
      </div>
      <div class="schedule">
        <label>대회기간</label>
        <p><b>골드/다이아</b><em>10월 16일(토) ~ 10월 28일(목)</em></p>
        <p><b>레디언트</b><em>10월 23일(토) ~ 11월 16일(화)</em></p>
      </div>
    </li>
    <li>
      <article>
        <h3><em>스테이지 안내</em></h3>
        <div class="inner-holder">
          <p>
            <span>인플루언서 이벤트 매치</span>
            <em>유명 인플루언서 2팀의</em> <b>이벤트 매치!</b>
          </p>
          <div class="grade gold">
            <span><img src="/img/pages/shotVal/landing/pc/tier_Gold.png"/></span>
            <h5 class="ft">GOLD</h5>
            <p>최고 티어 <b>골드 이하</b> 유저 참가</p>
          </div>
          <div class="grade diamond">
            <span><img src="/img/pages/shotVal/landing/pc/tier_Diamond.png"/></span>
            <h5 class="ft">DIAMOND</h5>
            <p>최고 티어 <b>다이아 이하</b> 유저 참가</p>
          </div>
          <div class="grade radiant">
            <span><img src="/img/pages/shotVal/landing/pc/tier_Radiant.png"/></span>
            <h5 class="ft">RADIANT</h5>
            <p><b>티어 제한 없이 참가</b></p>
          </div>
          <button class="btn-info" @click="goLanding">대회 안내 바로가기</button>
        </div>
      </article>
    </li>
  </ul>
</template>

<script>
import gtag from 'shared/mixins/gtag';

export default {
  name: 'ShotValDetail',
  mixins: [gtag],
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    goLanding() {
      this.trackEvent(`Main_landing_btn`, 'click', `${this.upRouteGameId}`);
      this.$router.push({ name: 'ShotValLanding' });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[official-league].VAL_SHOTVAL { .bg('@{val-home}/pc/bg-1.jpg'); .bg-s(1929, 1724); .bg-xc; .no-repeat; .bgc(rgba(26, 29, 34, 1));
  &:before { .cnt; .wh(188,100%); .contain('@{val-home}/pc/Side_layer_Left.png'); .abs; .lt(-26,0); pointer-events: none; .z(10); .t-x(-200); .tr-t(.3s);}
  &:after { .cnt; .wh(182,100%); .contain('@{val-home}/pc/Side_layer_right.png'); .abs; .rt(-46,0); pointer-events: none; .z(10); .t-x(200); .tr-t(.3s);}

  [p-section] {
    .intro-up(8, {
      &.slider-holder:after { .b(0);}
    });
    &.slider-holder { .crop; .w(100%); .rel;
      &:after {.cnt; .abs; .wh(1210, 174); .contain('@{bg}/val-bg-logo.svg'); .lb(0, -230); transition: bottom 1s, transform .7s;}
    }
    .card-list-holder { .rel;
      .msg { .tc; .wh(324, 44); .mh-c; .font-g; .fs(15, 44px); .mt(-30); .mb(20); .contain('@{val-home}/Title_bar_M.svg'); .ls(-1); .mh-c;
        b { .c(#F0E717);}
        em { .fs(11); }
      }
      .arrow-wrap { .block!important; .b(220);
        >button { .wh(36);
          >img {.wh(22); }
        }
      }
    }
    @media (@tl-up) {
      &.slider-holder:after { .l(50%); .t-xc;}
      .card-list-holder {
        .msg { .wh(880, 60); .fs(24, 60px); .mt(-50); .mb(60); .contain('@{val-home}/btn.svg');
          b { .pl(32); }
          em { .fs(16);}
        }
        .arrow-wrap { .b(220);
          >button { .wh(52);
            >img {.wh(36); }
          }
        }
      }
    }
    @media (@ds-up) { .rel;
      &.slider-holder:after { .wh(1530, 220); }
      .card-list-holder {
        .arrow-wrap { .b(208);
          .arrow-left { .l(80); }
          .arrow-right { .r(80); }
        }
      }
    }
    @media (min-width: 1420px) {
      .card-list-holder {
      }
    }
  }
  @media(min-width: 1500px) {
    &:before { .t-x(0); }
    &:after { .t-x(0); }
  }
}
.intro-up(5, {
  .img-logo { .jello; }
});
[shot-val-detail] { .tc; .p(0, 20); .rel; .z(2); .mb(20);
  > li {
    &:nth-of-type(1) {
      .img-logo { .wh(292, 140); .mh-c; .m(28, 0, 24);}
      h2 { .p(0, 30); .fs(28, 36px); .ls(-2);}
      .schedule { .mt(20);
        &:nth-of-type(2) {
          > label { .bgc(#D13639); }
        }
        > label { .block; .wh(72, 36); .br(30); .fs(14, 36px); .bgc(#5B5C61); .c(white); .mh-c; .mb(12); .tc;}
        > p { .c(white); .mb(8);
          > em { .o(.8); }
          > b { .ib; .mr(8); }
        }
      }
    }
    &:nth-of-type(2) { .mt(80);
      > article { .p(50, 10, 0); .wh(337, 572); .-box; .contain('@{val-home}/Stage_back_M.svg'); .mh-c;
        h3 { .fs(24); .ls(-2); .mb(36);
          > em { .ib;
            &:before, &:after { .cnt; .ib; .wh(58, 20); .vam; }
            &:before { .contain('@{val-home}/Text_Deco_M_L.svg'); .mr(20);}
            &:after { .contain('@{val-home}/Text_Deco_M_R.svg'); .ml(20);}
          }
        }
        .inner-holder { .p(0, 20);
          > p { .fs(14); .tl; .mb(20);52B54E
            > em { .o(.8);}
            > span { .wh(160, 32); .bgc(#3D3F45); .fs(14, 32px); .c(white); .bold; .block; .mb(12); .tc;}
          }
          .grade { .pl(84); .w(100%); .-box; .rel; .min-h(68); .mt(20); .tl;
            &:nth-of-type(1) { .mt(0); }
            &:nth-of-type(2) {
              &:before { .bgc(#6656FA); }
              > span {
                &:before { .contain('@{val-home}/tier_diamond_bg.svg');}
              }
            }
            &:nth-of-type(3) {
              &:before { .bgc(#5DD051); }
              > span {
                &:before { .contain('@{val-home}/tier_Radiant_bg.svg');}
              }
            }

            &:before { .cnt; .wh(100%, 2); .bgc(#FB2935); .mb(12);}
            > span { .wh(68); .abs; .lt(0, 0); .block;
              &:before { .cnt; .wh(100%); .contain('@{val-home}/tier_Gold_bg.svg'); .abs; }
              img { .wh(58); .rel; .z(2); .abs; .lt(50%, 50%); .t-xyc;}
            }
            h5 { .fs(26); .mb(0);
              & + p { .fs(14, 20px);
                b { .c(#F0E717); }
              }
            }
          }
          .btn-info { .bgc(#DC2E3C); .-a(#F7949C); .br(4); .font-g; .fs(18, 42px); .bold; .wh(195, 42); .c(white); .mt(32); }
        }
      }
    }
  }
  @media (@tl-up) {
    > li {
      &:nth-of-type(1) { .pl(452); .w(850); .mh-c; .mt(90); .rel;
        > h2 { .tl; .p(0); .mb(28); }
        .img-logo { .wh(392, 188); .abs; .lt(0, 0);}
        .schedule { .w(400); .tl; .pl(96); .-box; .rel; .mt(16);
          > label { .abs; .lt(0, 0);}
          > p { .fs(16);
            > b { .mr(12);}
          }
        }
      }
      &:nth-of-type(2) {
        > article { .wh(682, 368); .mh-c; .p(0); .pt(50); .rel; .tl; .contain('@{val-home}/pc/Stage_back.svg');
          h3 { .mb(28);
            > em {
              &:before { .wh(32, 28); .bgc(#D02534); .mr(10);}
            }
            & + p { .ib-list; .fs(16); .mb(25);
              > * { .vam;}
              > button { .wh(182, 36); .vam; .ib; .fs(16, 36px); .mr(12); .mb(0); }
            }
          }
          .inner-holder {.p(0, 42);
            > p { .ib-list; .fs(16); .mb(25);
              > * {.vam; }
              > span { .ib; .mb(0); .mr(12); .wh(182,36); .fs(16,36px); }
            }
            .grade { width: calc(50% - 18px); .ib; .mt(0); .mb(10); .vat; .tl;
              &:nth-of-type(1){ .mb(30);}
              &:nth-of-type(even) { .ml(36); }
              h5 {  .fs(28);
                &+p { .fs(16); .min-w(210); }
              }
            }
            .btn-info { .abs; .rt(20, 40); .mt(0); }
          }
        }
      }
    }
  }
  @media (@ds-up) {
    .clear; .p(0); .pb(20); .ib-list; .tl;
    > li {
      &:nth-of-type(1) { .w(440); .pl(0); .mt(48); .ml(30);
        .img-logo { .wh(100%, 211); .static; .block; .mt(0); }
        > h2 { .block; }
      }
      &:nth-of-type(2) { .fr; .mt(160);
      }
    }
  }
}
</style>
