<template>
  <div>
    <HomeHeader />
    <OfficialLeague is-tab :officialLeagueData="officialLeagueData">
      <template #detail>
        <ShotValDetail />
      </template>
      <template #gameInfo>
        <ShotValGameInfo />
      </template>
    </OfficialLeague>
    <client-only><MyInfo /></client-only>
  </div>
</template>

<script>
import HomeHeader from '@/views/components/gameHome/HomeHeader.vue';
import MyInfo from '@/views/components/gameHome/MyInfo.vue';
import OfficialLeague from '@/views/components/gameHome/OfficialLeague.vue';
import ShotValDetail from '@/views/components/gameHome/valorant/ShotValDetail.vue';
import ShotValGameInfo from '@/views/components/gameHome/valorant/gameInfo/ShotValGameInfo.vue';
import officialLeagueData from '@/data/official-league/officialLeagueData';
import meta from '@/data/meta';

export default {
  name: 'ValorantHome',
  components: { MyInfo, HomeHeader, OfficialLeague, ShotValDetail, ShotValGameInfo },
  computed: {
    base() {
      return this.$store.state.baseInfo;
    },
    officialLeagueData() {
      return officialLeagueData[this.$route.params.id];
    },
  },
  methods: {
    toInit() {
      setTimeout(() => {
        const target = document.querySelector('.league');
        this.$scroll.scrollMove(target.offsetTop + (this.matchedMediaDevice === 'M' ? 450 : 700), 1000);
      }, 200);
    },
  },
  mounted() {
    if (this.$route.query.page && this.$route.query.page === 'arena') this.toInit();
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[valorant-home] {}
</style>
