<template>
  <article shot-val-third-slide>
    <div class="inside-holder">
      <h3><em>특별 보상</em></h3>
      <div class="img-holder">
        <img src="/img/pages/shotVal/landing/specialReward1.png" />
        <img src="/img/pages/shotVal/landing/specialReward2.png" />
      </div>
      <p>
        <em> 각 스테이지 본선 진출 시</em> <b class="y">발로란트 1주년 기념 굿즈 <br />& 한미 마이크로닉스<br />게이밍 기어세트 획득!</b>
      </p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ShotValThirdSlide',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[shot-val-third-slide] { .tc;
  .inside-holder { .max-w(492); .mh-c;
    h3 { .font-g; .fs(28); .ls(-2); .mb(15); }
    > .img-holder {
      > img { .wh(251, 151); .mh-c; .block; .mb(10);}
    }
    > p { .mt(18);
      > em { .fs(18); }
      > b { .fs(22); .block; .mt(8);}
    }
  }
  @media (@tp-up) {
    .inside-holder {
      .h3 { .mb(40); }
      > .img-holder {
        > img { .ib; .mt(0); .wh(240, 142); .vam;
          &:nth-of-type(1) { .mr(10);}
        }
      }
      > p { .mt(35);
        > em { .fs(20); }
        > b { .fs(24); .mt(10);
          > br {
            &:nth-of-type(1) { .hide;}
          }
        }
      }
    }
  }
  @media (@ds-up) {
  }
}
</style>
