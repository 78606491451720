<template>
  <PSection shot-val-game-info theme="white">
    <div class="contents-holder">
      <div class="left-holder">
        <ShotValRewards />
      </div>
      <div class="right-holder">
        <ShotValResult />
      </div>
    </div>
  </PSection>
</template>

<script>
import ShotValRewards from '@/views/components/gameHome/valorant/gameInfo/detailSection/ShotValRewards.vue';
import ShotValResult from '@/views/components/gameHome/valorant/gameInfo/detailSection/ShotValResult.vue';

export default {
  name: 'ShotValGameInfo',
  components: { ShotValRewards, ShotValResult },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[shot-val-game-info] { .bgc(#15181d) !important; .p(47, 20, 60); .rel; .z(11);
  @media (@tl-up) {
    .p(60, 58);
  }
  @media (@ds-up) {
    .p(90, 0);
    .contents-holder {.ib-list; .w(@screen-ds-min); .mh-c;
      > * {.-box;}
    }
    .left-holder { .pr(80); .w(640); .rel;
      &:after {.cnt; .rt(0, 0); .wh(1, 100%); .bgc(rgba(255, 255, 255, .3)); .abs;}
    }
    .right-holder { .pl(80); .w(620); }
  }
}
</style>
