<template>
  <article shot-val-first-slide>
    <div class="inside-holder">
      <h3><em>총 상금 4,500 만원!</em></h3>
      <ul>
        <li>
          <h5>골드</h5>
          <p class="grade">
            <span><em>1위</em><b class="y">200만원</b></span>
            <span><em>2위</em><b class="y">100만원</b></span>
            <span><em>공동 3위</em><b class="y">50만원</b></span>
          </p>
        </li>
        <li>
          <h5>다이아</h5>
          <p class="grade">
            <span><em>1위</em><b class="y">250만원</b></span>
            <span><em>2위</em><b class="y">150만원</b></span>
            <span><em>공동 3위</em><b class="y">50만원</b></span>
          </p>
        </li>
        <li>
          <h5>레디<br />언트</h5>
          <p class="grade">
            <span><em>1위</em><b class="y">1,300만원</b></span>
            <span><em>2위</em><b class="y">600만원</b></span>
            <span><em>공동 3위</em><b class="y">400만원</b></span>
            <span class="group-reward">5 ~ 8위 <b>100만원</b> / 9 ~ 16위 <b>50만원</b></span>
          </p>
        </li>
      </ul>
      <div class="mvp">
        <p><b>MVP</b><em>상금</em><b class="y">100만원</b></p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ShotValFirstSlide',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[shot-val-first-slide] { .tc;
  .inside-holder {
    > ul {
      > li { .mb(16);
        &:nth-of-type(2) {
          h5 { .contain('@{val-home}/Diamond.svg'); }
          .grade:after { .bgc(#6656fa); }
        }
        &:nth-of-type(3) {
          h5 { .contain('@{val-home}/Radiant.svg'); }
          .grade:after { .bgc(#5dd051); }
        }
        h5 { .fs(16, 36px); .regular; .wh(100%, 36); .contain('@{val-home}/Gold.svg');
          br { .hide; }
        }
        .grade { .w(100%); .bgc(transparent); .p(18, 0); .bgc(rgba(0, 0, 0, .2)); .rel; .font-g;
          &:after { .cnt; .wh(100%, 1px); .bgc(#d02534); .o(.5); .lb(0, 0); .abs; }
          > span { .ib;
            &:nth-of-type(1) em { .ml(0);
              &:before { content: none;}
            }
            > em { .fs(14); .ls(-2); .mr(4); .ml(12); .ib; .rel;}
            > b { .fs(16); .ls(-1); .ib;}
            &.group-reward { .block; .fs(12); .c(white); .o(.5); .mt(8);
              b { .c(white); .fs(12); }
            }
          }
        }
      }
    }
    .mvp { .p(20, 0); .bgc(rgba(0, 0, 0, .1)); .-v(rgba(93, 208, 81, .5));
      > p { .ib-list; .fs(20) ; .ls(-1); .font-g;
        > * {.vam; .rel;}
        .y { .fs(24); .ls(-2);
          &:after { .cnt; .wh(4, 4); .bgc(#d02534); .abs; .rt(-12, 50%); transform: translateY(-50%) rotate(45deg); }
        }
        em { .fs(16); .m(0, 8, 0, 22);
          &:before { .cnt; .wh(4, 4); .bgc(#d02534); .abs; .lt(-12, 50%); transform: translateY(-50%) rotate(45deg); }
        }
      }
    }
  }
  @media (@tp-up) {
    .inside-holder {
      > ul {
        > li { .pl(80); .rel; .mb(20);
          &:nth-of-type(2) {
            h5 { .contain('@{val-home}/pc/Diamond.svg'); }
            .grade:before { .bgc(#6656fa); }
          }
          &:nth-of-type(3) {
            h5 { .contain('@{val-home}/pc/Radiant.svg'); }
            .grade { .p(12, 0);
              &:before { .bgc(#5dd051);}
            }
          }
          h5 { .wh(80, 100%); .ib; .abs; .lt(0, 0); .flex-center; .fs(18, 22px); .-box; .contain('@{val-home}/pc/Gold.svg');
            br { .block; }
          }
          .grade { .w(440); .ib; .p(14, 0);
            &:before { .cnt; .wh(100%, 1px); .bgc(#d02534); .o(.5); .lt(0, 0); .abs; }
            > span {
              > em { .fs(16); .ml(22);
                &:before { .cnt; .wh(4, 4); .bgc(#d02534); .abs; .lt(-12, 50%); transform: translateY(-50%) rotate(45deg); }
              }
              > b { .fs(20); }
              &.group-reward { .fs(16);
                b { .fs(16);}
              }
            }
          }
        }
      }
      .mvp { .p(14, 0);
        > p { .fs(24);
          .y { .fs(28); }
          em { .fs(20); }
        }
      }
    }
  }
}
</style>
