<template>
  <div shot-val-radiant-result>
    <div radiant-stage>
      <h3 class="radiant">레디언트 그룹 스테이지 결과</h3>
      <table>
        <thead>
          <tr>
            <th>그룹</th>
            <th>8강 진출팀</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan="2" class="group">A그룹</td>
            <td>이사가자</td>
          </tr>
          <tr>
            <td>TNL Esports</td>
          </tr>
          <tr>
            <td rowspan="2" class="group">B그룹</td>
            <td>Wyvern</td>
          </tr>
          <tr>
            <td>WGS X-IT</td>
          </tr>
          <tr>
            <td rowspan="2" class="group">C그룹</td>
            <td>ICE Gunmandu</td>
          </tr>
          <tr>
            <td>Alpha Six Gaming</td>
          </tr>
          <tr>
            <td rowspan="2" class="group">D그룹</td>
            <td>Spear Gaming</td>
          </tr>
          <tr>
            <td>VVS_</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShotValRadiantResult',

};

</script>

<style lang="less">
@import '~@/less/proj.less';

[shot-val-radiant-result] {
  [radiant-stage] { .tc; .w(303); .mh-c; .mt(45);
    h3 { .wh(303, 44); .fs(18, 44px); .c(white); .tc; .mh-c; .mb(10); .bg('@{val-home}/leaderboard/m/subtitle/subtitle_type=rad.svg'); .no-repeat; }
    table {
      thead { .fs(14, 14px); .tc; .c(#89898a); .medium; .bgc(#131315);
        > tr {
          > th { .-r(#27292d, 1px); .h(30); }
        }
      }
      tbody { .bgc(#191b1f);
        > tr { .-b(#d2d0ca, 1px);
          > td { .fs(14); .c(white); .bold; .mb(4); .-a(#27292d, 1px); .tc; .h(30);
            > img { .wh(20, 24);}
          }
          .group { .regular;}
        }
      }
    }
  }
  @media (@tp-up) {
    [radiant-stage] { .w(482); .mh-c;
      h3 { .wh(482, 40); .fs(18, 40px); .bg('@{val-home}/result/rad_1920.svg'); .no-repeat; background-size: contain;}
    }
  }
}

</style>
