<template>
  <div shot-val-gold-result>
    <div gold-result>
      <h3 class="gold">골드 스테이지 1,2,3위 팀</h3>
      <table>
        <thead>
          <tr>
            <th>순위</th>
            <th>팀명</th>
            <th>본선 보상</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank1.svg" />
            </td>
            <td>LucidDream</td>
            <td>200만원</td>
          </tr>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/2.svg" />
            </td>
            <td>처음초롬</td>
            <td>100만원</td>
          </tr>
          <tr>
            <td rowspan="2">
              <img src="/img/pages/shotVal/home/leaderboard/rank/3.svg" />
            </td>
            <td>늪지대</td>
            <td rowspan="2">각 50만원</td>
          </tr>
          <tr>
            <td>Citizen</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div diamond-result>
      <h3 class="diamond">다이아 스테이지 1,2,3위 팀</h3>
      <table>
        <thead>
          <tr>
            <th>순위</th>
            <th>팀명</th>
            <th>본선 보상</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank1.svg" />
            </td>
            <td>Pokemon Masters</td>
            <td>250만원</td>
          </tr>
          <tr>
            <td>
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank2.svg" />
            </td>
            <td>NINAVE</td>
            <td>150만원</td>
          </tr>
          <tr>
            <td rowspan="2">
              <img src="/img/pages/shotVal/home/leaderboard/rank/leaderboard_rank3.svg" />
            </td>
            <td>FTS gaming</td>
            <td rowspan="2">각 50만원</td>
          </tr>
          <tr>
            <td>Drama Fan</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShotValGoldResult',

};

</script>

<style lang="less">
@import '~@/less/proj.less';

[shot-val-gold-result] {
  h3 { .wh(303, 44); .fs(18, 44px); .c(white); .tc; .mh-c; .mb(10); .no-repeat; }
  table {
    thead { .fs(14, 14px); .tc; .c(#89898a); .medium; .bgc(#131315);
      > tr {
        > th { .-r(#27292d, 1px); .h(30);}
      }
    }
    tbody { .bgc(#191b1f);
      > tr { .-b(#d2d0ca, 1px);
        > td { .fs(14); .c(white); .bold; .mb(4); .-a(#27292d, 1px); .tc; .h(30);
          &:nth-of-type(3) { .c(#ffc700);}
          > img { .wh(20, 24);}
        }
      }
    }
  }
  [gold-result] { .tc; .w(303); .mh-c;
    h3 {.bg('@{val-home}/leaderboard/m/subtitle/subtitle_type=gold.svg');}
  }
  [diamond-result] { .tc; .w(303); .mh-c;
    h3 { .mt(26); .bg('@{val-home}/leaderboard/m/subtitle/subtitle_type=dia.svg');}
  }

  @media (@tp-up) {
    [gold-result] { .w(482); .mh-c;
      h3 { .wh(482, 40); .fs(18, 40px); .bg('@{val-home}/result/gold_1920.svg'); .no-repeat; background-size: contain;}
    }
    [diamond-result] { .w(482); .mh-c;
      > h3 { .wh(482, 40); .fs(18, 40px); .bg('@{val-home}/result/dia_1920.svg'); .no-repeat; background-size: contain;}
    }
  }
}

</style>
