<template>
  <div shot-val-result>
    <official-league-game-info-template :title="titleData" @more-click="goLeaderboard">
      <div class="context">
        <div class="result-holder">
          <ShotValGoldResult v-if="goldStage" />
          <ShotValRadiantResult v-else-if="radiantStage" />
          <ShotValMvpResult v-else-if="mvpStage" />
        </div>
      </div>
    </official-league-game-info-template>
  </div>
</template>

<script>
import OfficialLeagueGameInfoTemplate from '@/views/components/gameHome/OfficialLeagueGameInfoTemplate.vue';
import ShotValGoldResult from '@/views/components/gameHome/valorant/gameInfo/detailSection/ShotValGoldResult.vue';
import ShotValRadiantResult from '@/views/components/gameHome/valorant/gameInfo/detailSection/ShotValRadiantResult.vue';
import ShotValMvpResult from '@/views/components/gameHome/valorant/gameInfo/detailSection/ShotValMvpResult.vue';

export default {
  name: 'ShotResult',
  components: { ShotValMvpResult, ShotValRadiantResult, ShotValGoldResult, OfficialLeagueGameInfoTemplate },
  data: () => ({
    titleData: {
      title: 'RESULT',
      titleKo: '대회 결과',
      subTitle: '',
      more: true,
    },
  }),
  mounted() {
    this.titleData.subTitle = this.subTitles;
  },
  computed: {
    subTitles() {
      if (this.goldStage) {
        return '골드/다이아 스테이지 본선 결과를 공개합니다.';
      } if (this.radiantStage) {
        return '레디언트 스테이지 그룹 스테이지 결과를 공개합니다.';
      }
        return '레디언트 스테이지 본선 결과와 MVP를 공개 합니다.';
    },
    todayTimeStamp() {
      return new Date().getTime();
    },
    goldStage() {
      const startTimeStamp = new Date('2021-10-29').getTime();
      const endTimeStamp = new Date('2021-11-05').getTime();

      return startTimeStamp <= this.todayTimeStamp && this.todayTimeStamp < endTimeStamp;
    },
    radiantStage() {
      const startTimeStamp = new Date('2021-11-05').getTime();
      const endTimeStamp = new Date('2021-11-18').getTime();

      return startTimeStamp <= this.todayTimeStamp && this.todayTimeStamp < endTimeStamp;
    },
    mvpStage() {
      const startTimeStamp = new Date('2021-11-18').getTime();

      return startTimeStamp <= this.todayTimeStamp;
    },
  },
  methods: {
    goLeaderboard() {
      this.$router.push('/b/val/record');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[shot-val-result] {
  [official-league-detail-template] {
    .sub-title { .mb(45); }
    .context { .bgc(#24262c); .wh(100%, 496); .p(40, 16); .-a(rgba(255, 255, 255, 0.1), 1px); .br(8);
    }
    @media (@tp-up) {
      .context { .h(520); .pt(50);
      }
    }
  }
}

</style>
