<template>
  <Slider
    item-reward-slider
    :option="option"
    :pagination="slides.length > 1"
    pagination-type="bullets"
    :loop="slides.length > 1"
    ref="slider"
    @touchIn="touchStart"
    @touchOut="touchEnd"
    :info="slides"
    navigation
  >
    <template v-slot:slide="{info}">
      <component :is="info"></component>
    </template>
  </Slider>
</template>

<script>
import Slider from '@shared/components/common/Slider.vue';

export default {
  name: 'OfficialLeagueSlider',
  components: { Slider },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loopId: null,
      option: {
        touchRatio: 1,
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 0,
        autoplay: true,
        breakpoints: {
          1024: {
            slidesPerView: 1,
            centeredSlides: false,
          },
          1280: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[item-reward-slider] { .mh-c; .mt(45); .bgc(#24262c); .p(28, 0, 19, 0); .mb(67); .br(8); .-a(rgba(255, 255, 255, 0.1)); .-box; .rel;
  .swiper-slide { .w(335); .tc; .mr(0);
    .reward { .bgc(#1e54b7); .wh(240, 40); .br(12); .mh-c; .mb(12); .fs(20, 40px); .ls(-1);}
    .msg { .o(.8); .c(white); .fs(12); .font-g; .ls(-.5);}
    .sign {.fs(12, 16px); .c(white); .o(.5); .tc; .w(270); word-break: keep-all; .mh-c; .mt(20);}
  }
  .pagination { position: unset; .lt(0, 0); .block; .pt(21); .tc;}

  @media (@tl-up) {
    .swiper-slide { .w(533);
      .reward {.wh(280, 48); .fs(24, 48px);}
      .msg { .fs(14);}
      .sign {.fs(13); .w(100%); .mt(20);}
    }
  }

  @media (@ds-up) {
    .mb(0);
  }
}

</style>
