<template>
  <div shot-val-rewards>
    <OfficialLeagueGameInfoTemplate :title="titleData" :slides="slides" />
  </div>
</template>

<script>
import OfficialLeagueGameInfoTemplate from '@/views/components/gameHome/OfficialLeagueGameInfoTemplate.vue';
import ShotValFirstSlide from '@/views/components/gameHome/valorant/gameInfo/slides/ShotValFirstSlide.vue';
import ShotValSecondSlide from '@/views/components/gameHome/valorant/gameInfo/slides/ShotValSecondSlide.vue';
import ShotValThirdSlide from '@/views/components/gameHome/valorant/gameInfo/slides/ShotValThirdSlide.vue';

export default {
  name: 'ShotValRewards',
  components: { OfficialLeagueGameInfoTemplate },
  data: () => ({
    titleData: {
      title: 'REWARD',
      titleKo: '대회 보상',
      subTitle: '총 상금 4,500만원을 건 발로란트 유저들의 대축제!',
    },
    slides: [ShotValFirstSlide, ShotValSecondSlide, ShotValThirdSlide],
  }),
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[shot-val-rewards] {
  [item-reward-slider] {
    .swiper-slide { .p(12, 0, 30); .tc; .font-g;
      .y { .c(#f0e717); }
      .inside-holder { .w(304); .mh-c;
        h3 { .font-g; .fs(28); .ls(-2); .mb(38);
          &:after { .cnt; .wh(88, 10); .contain('@{val-home}/Reward_Title_vector_M.svg'); .mh-c; .mt(20); }
          em { .ib;
            &:before, &:after { .cnt; .ib; .wh(5, 36); .vam; }
            &:before { .contain('@{val-home}/Title_deco_L.svg'); .mr(20);}
            &:after { .contain('@{val-home}/Title_deco_R.svg'); .ml(20);}
          }
        }
      }
      @media (@tp-up) {
        .inside-holder { .w(520);
          h3 { .mb(40);
            &:after { content: none;}
            em {
              &:before, &:after { .cnt; .ib; .wh(92, 36); }
              &:before { .contain('@{val-home}/pc/Reward_Title_vector_left.svg'); .mr(28);}
              &:after { .contain('@{val-home}/pc/Reward_Title_vector_right.svg'); .ml(28);}
            }
          }
        }
      }
      @media (@ds-up) {
      }
    }
  }
}
</style>
