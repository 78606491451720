<template>
  <article shot-val-second-slide>
    <div class="inside-holder">
      <h3><em>단계 별 보상</em></h3>
      <ul>
        <li>
          <label>1단계</label>
          <p>
            <em>참가 신청을 완료한 전원에게</em> <b class="y">매운맛 총기장식 증정</b>
          </p>
        </li>
        <li>
          <label>2단계</label>
          <p>
            <em>팀을 구성하고 최초 경기 진행 시</em> <b class="y">에피소드2 포메이션 <br />플레이어 카드 증정</b>
          </p>
        </li>
        <li>
          <label>3단계</label>
          <p>
            <em>최초 1승 시</em> <b class="y">발로란트 마우스 장패드 <br />증정</b>
          </p>
        </li>
      </ul>
    </div>
  </article>
</template>

<script>
export default {
  name: 'ShotValSecondSlide',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[shot-val-second-slide] { .tc;
  .inside-holder { .w(330); .mh-c;
    h3 { .font-g; .fs(28); .ls(-2); .mb(40); }
    > ul { .mt(104); .tl;
      > li { .pl(88); .rel; .flex-middle; .h(68); .mb(36);
        &:before { .cnt; .abs; .lt(25, 80); .wh(17, 11); .contain('@{val-home}/yellowArrow.svg');}
        &:nth-of-type(3) {
          &:before { .hide;}
        }
        > label { .block; .wh(68); .lt(0, 50%); .t-yc; .abs; .fs(18); .ls(-1); .flex-center; .-a(#d02534); .br(50%);}
        > p {
          > em { .fs(14); .ls(-1); }
          > b { .block; .mt(8); .fs(18); .ls(-1); }
        }
      }
    }
  }

  @media (@tp-up) {
    .inside-holder { .w(420) !important;
      .h3 { .mb(46); }
      > ul { .mt(0);
        br { .hide; }
        > li {
          > p {
            > em { .fs(16); }
            > b { .fs(20); }
          }
        }
      }
    }
  }
  @media (@ds-up) {
  }
}
</style>
