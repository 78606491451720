import { render, staticRenderFns } from "./ShotValGoldResult.vue?vue&type=template&id=cae2f984&"
import script from "./ShotValGoldResult.vue?vue&type=script&lang=js&"
export * from "./ShotValGoldResult.vue?vue&type=script&lang=js&"
import style0 from "./ShotValGoldResult.vue?vue&type=style&index=0&id=cae2f984&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports